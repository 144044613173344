@import '../global.css';
@import 'revealer.css';
@import '../landscape-blocker.css';


:root{
    $box-transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    --brand-color: $brand-color;
}

body{
    --gap: 10px;
    --body-bg: #fff;
    --grid-nav-text-color: #fff;
    --grid-nav-text-hover-color: #fff;
    --collection-color: $brand-color;
    background: var(--body-bg);
    background-image: linear-gradient(0deg, #fdfbfb 0%, #ebedee 100%);
}
.icon{
    width: 1.5em;
    height: 1.5em;
    fill: currentColor;
}

.hidden {
    height: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    width: 0;
}


.no-js .grid {
    margin: 0 0 15vh;
}

.js .grid {
    @media (--phone) {
        opacity: 1;
    }

    @media (--tablet-portrait) {
        opacity: 0;
    }
}

/*
 * Flex na małym, grid na dużym
*/


.icons-container div {
        @media (--tablet-portrait) {
            box-shadow: 0px 0px 4px 0 rgba(0,0,0,.2);
            }
    }

.icons-container {
    display: grid;
    grid-row: 3;
    grid-column: 1/5;
    justify-content: center;
    /* @mixin layoutcalc margin-top,12,-.5; */
    /*@mixin layoutcalc margin-left margin-right,12,0.25; */
    
    @mixin layoutcalc margin-top,12, .25;
    
    align-items: start;
    grid-template-columns: repeat(4, 1fr);
    @mixin layoutcalc padding-bottom padding-left padding-right,12,0.25; 
    padding-top: 0; 

    svg {
        .bg{
            fill: $brand-color;
        }
        .content{
            fill: white;
        }
        text{
            fill: $brand-color;
            font-size: 360px;
            stroke-width: 0;
            width: 1500px;
            text-transform: uppercase;
        }
    }
        @media (--tablet-landscape){

           padding: 0;
           align-items: stretch;
           margin: 0 0 0 0;
        }

/**
* Domyślnie ikonki maja display: none
*/
    .grid-item {
        display: none;
        a{
            display: block;
            height: 100%;
        }
    }

/**
 * Ikonki z tymli klasami są pokazywane w telefonie
 */
    .grid-item.mobile-only,
    .grid-item.mobile-desktop {
        display: initial;
    }




    @media (--tablet-portrait) {
        display: grid;
        grid-area: 9/17/13/25;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: repeat(4,1fr);
        grid-gap: var(--gap);
        margin-top: unset;


        .grid-item {
            display: initial;
            &.mobile-only{
                display: none;
            }
        }

        svg{
            .bg{
                fill: #fff;
            }
            .content{
                fill: $brand-color;
            }
            text{
                fill: #fff;
                /* font-size: 350px; */
            }
        }

    }

}
.mobile-desktop{
    display: initial;
}
.slideshow-container{
    .menu-item-home.active {
        background: #FFF;
        color: $brand-color;
        border: none !important;
    }

    .menu-item.active {
        color: #fff;
        background: var(--collection-color);
    }
}

.grid-item {
    position: relative;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    transition: transform 0.2s ease-out;

    a div span {
        display: none;
    }
}



.no-js .grid-item-nav {
    display: none;
}

.grid-item-animateOut {
    animation: animateOut 0.8s cubic-bezier(0.7, 0, 0.3, 1) forwards;
}

@keyframes animateOut {
    to {
        opacity: 0;
    }
}

.grid-item-animateIn {
    animation: animateIn 0.8s cubic-bezier(0.7, 0, 0.3, 1) forwards;
}

@keyframes animateIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


.view-transition {
    transition: opacity 0.3s linear;
}

.grid-switcher {
    background: yellow;
    z-index: 100;
    grid-area: 1/24/2/25;
}

svg.switcher {
    width: 65px;
    margin: 0 2rem;

}

.menu-item-2.navbar-switcher {
    display: flex;
    justify-content: space-evenly;
    grid-column: 3/6;
    grid-row: 1/2;


}

.menu-bar{
	transition: opacity 0.2s linear;
	opacity: 1;
    @extend %no-tap;
}
.menu-bar.hidden{
	opacity:0;
}

.menu-bar .menu-bar-switcher.disabled{
	display: none;
}
.menu-bar .menu-bar-switcher .switch-left,
.menu-bar .menu-bar-switcher .switch-right {
    color: var(--collection-color);
    transition: background 0.3s linear, color 0.2s linear;
}

.navbar-switcher.right .navbar-handle{
	transform: translateX(1500px);
}

.navbar-handle.fil1 {
    fill: white;

}
.switcher .fil0 {
    fill: var(--collection-color);
    transition: fill 0.3s linear;
}

.navbar-handle{
    transition: transform 0.1s linear;
}

.category-view-content {
    grid-auto-columns: auto;
    grid-auto-flow: column;
}

.slideshow-container{
    .menu-item-home.active {
        background: #FFF;
        color: $brand-color;
        border: none !important;
    }

    .menu-item.active {
        color: #fff;
        background: var(--collection-color);
    }
    .menu-item.active:hover {
        filter: var(--item-shadow);
    }
}



.grid-item-news-date {
    color: #999;

}

.grid-item-news-categories {
    color: #999;
    a {
        color: #999;
    }
}

.grid-item-news-btn {
    text-transform: uppercase;
    color: #ffffff;
    background: $brand-color;
    padding: 0.6rem 1.2rem;
    text-decoration: none;
    text-align: center;
    float: right;
    margin-top: 2vh;
}

.grid-item-news-btn:hover {
    background: #189e1a;
    color: unset;
}

.grid-itemimg {
    width: 65%;
    margin: 0.5rem;

    @media(--tablet-landscape){
        display: grid;
        margin: 0 auto 0.5rem;
        width: 50%;
    }
}

.grid-item-news-btn a {
    font-size: 0.9rem;
    color: #FFF;
}

.grid-item-news-btn a:hover {
    color: #FFF;
}

p.menu-item {
    display: grid;
    text-transform: uppercase;
    /* font-size: interpolate(1200px, 1.25rem, 1800px, 1.45rem); */
    @mixin layoutcalc font-size, 12, 0.15;
    font-weight: 200;
    justify-content: center;
    align-content: center;
    @mixin layoutcalc margin-top margin-bottom, 12, 0.04;
    color: var(--collection-color);
    cursor: pointer;
}

/*
ikonki w boxach na stronie głównej
*/

.grid-item-box {
    width: 100%;
    height: 100%;
    display: grid;
    align-content: center;
    justify-content: center;
    /* background: #FAFBFC; */

    svg {
            @mixin layoutcalc width, 12, .65;
            height: auto;

        .round {
            fill: $brand-color;
        }

        .logo-content {
            fill: white;
        }

        .font {
            font-weight: 400;
            font-size: 4.17px;
            text-transform: uppercase;
        }

        .icon-description {
            fill: $brand-color;
        }

        @media(--tablet-landscape){

            /* height: 65px; */

            .icon-description {
                fill: white;
            }

            .logo-content {
                fill: $brand-color;
            }

            .round {
                fill: white;
            }
        }
    }
}

.main-container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    height: 100vh;
    width: 100%;
    padding: 0;

    @media (--tablet-landscape){
        grid-template-rows: 12fr 1fr 1fr;
    @mixin layoutcalc padding-top, 12, 1;
    @mixin layoutcalc padding-bottom, 12, 0.5;
    }
}


.menu-bar {
    display: none;

    @media (--tablet-landscape){
        display: grid;
        grid-area: 2 / 1 / 4 /2;
        /* grid-template-columns: auto repeat(6, auto); */
        grid-template-columns: auto repeat(4, auto);
        grid-template-rows: 1fr 1fr;
        padding: 0 17%;

        .menu-item {
            grid-row: 2/3;
            transition: background 0.3s linear, color 0.2s linear;
            border-right: 1px solid;
        }

        .menu-bar-switcher{
            display: flex;
            justify-content: center;
            grid-column: 2/8;
            grid-row: 1/2;
            align-items: center;
            text-transform: uppercase;
            cursor: pointer;
            @mixin layoutcalc font-size, 12, 0.12;
        }

    }
}

.slider-container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity .2s cubic-bezier(.68, .55, .27, 1.55);
    width: 100%;
    @media (--tablet-landscape){
        @mixin layoutcalc padding-left padding-right, 12, 0.5;
        }


    .grid:nth-child(n+2) {
        display: none;

        @media (--tablet-landscape){

            display: grid;
        }
    }
    
          
    h2, h3 {
        color: var(--collection-color);    	
        margin: 0; /* nadpisuje domyślne marginesy dla całej strony */
        }
}

.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 3fr 4fr 120px;

    @media (--tablet-landscape){
        align-content: space-between;
        grid-area: 1 / 1 / 2 / 2;
        grid-gap: var(--gap);
        grid-template-columns: repeat(24, calc((100% / 24) - var(--gap)));
        grid-template-rows: repeat(12, calc((100% / 12) - var(--gap)));
        justify-content: space-between;
        pointer-events: none;

        &.grid-layout-catalog {
            grid-auto-columns: minmax(100px,300px);
            grid-auto-rows: minmax(100px,400px);
            grid-auto-flow: column;
            grid-template-rows: unset;
            grid-template-columns: unset;
            align-content: center;
            justify-content: center;
        }
    }
}


.slideshow {
    height: 100%;
    width: 100%;
}

.js .grid.grid-current {
    opacity: 1;
    pointer-events: auto;
}

.grid-layout-catalog .grid-item{
	overflow: hidden;
}
.grid-layout-catalog .grid-item:hover .catalog-system-bg{
	transform: scale(1.1);
}
.catalog-content{
	position: absolute;
    display: grid;
    width: 100%;
    height: 100%;
	@mixin layoutcalc padding,12,0.1;
    grid-template-rows: 1fr 5fr 1fr;
    
    a, p.grid-item.grid-item-category, p.catalog-system-name {
        display: grid;
        text-transform: uppercase;
        /* width: 87%; */
        /* margin-right: auto;
        margin-left: auto; */
        align-content: center;
        justify-content: center;
        /* margin: 0 auto; */
    }
    
    p {
        
        @mixin layoutcalc font-size,12,0.15;
        font-weight: 400;
        
        &.grid-item-category {     
            background: rgba(255,255,255,0.9); 
            margin: 0; 
            }  

        &.catalog-system-name {
            background: none;    
            align-content: end;
            grid-template-columns: 1fr;
            
            span {
                background-color: rgba(255,255,255,0.75);    
                text-align: center;
                @mixin layoutcalc padding,12,0.1;
                }
        }
    }
    
    a {
        background: $brand-color;
        color: #fff;
        /* @mixin layoutcalc margin-top,12,0.05; */
        
    }
}
.catalog-system-bg{
	position:absolute;
	width:100%;
	height: 100%;
	background: inherit;
	transition: transform 0.3s linear;
	transform: scale(1.0);
}
