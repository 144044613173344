@charset "UTF-8";
@custom-media --portrait (orientation: portrait);
@custom-media --landscape (orientation: landscape);
@custom-media --phone (width <=600px) and (--portrait);
@custom-media --tablet-portrait (width > 600px);
@custom-media --tablet-landscape (width > 900px);
@custom-media --desktop (width > 1200px);
@custom-media --big-desktop (width > 1800px);
@custom-media --blocker (width<=900px) and (--landscape);

:root {
    --layoutWidth: 161.8vh;
}

$hero-ico-shadow: 9px 9px 5px 0 rgba(0, 0, 0, .2);
$brand-color: #207822;
$brand-font-color: #1f7c23;
$brand-darkness-color: color($brand-color b(60%));
$second-color: #d4d4d4;
$h1-gradient: linear-gradient(-225deg, #FEFEFC 0%, #d5d5d5 100%);
$menu-gradient-1: linear-gradient(90deg, #FEFEFC 0%, #d5d5d5 100%);
$margin-shadow-left: linear-gradient(90deg, rgb(21, 21, 21) 90%, #0e0e0e 100%);
$margin-shadow-right: linear-gradient(-90deg, rgb(21, 21, 21) 90%, #0e0e0e 100%);
$cookies-color: #042548;
$pcon-color: #FE3C02;
$chairs-color: #6d4c72;
$other-color: #e3b930;
$dotacje-color: #02346F;
$layoutWidth: 161.8vh;
$heroHeight: 45vh;

%section-responsive {
    grid-template-columns: 2fr 10fr;

    @media (--desktop) {
        grid-template-columns: 3fr 9fr;
    }
}

%no-tap {
    outline: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
