@import "global.css";

@media (--blocker){
    body{
        overflow: hidden;
    }
    .landscape-blocker{

        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background: #087a20;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            animation: landscapeSwitcher 2s linear infinite;
            width: 30%;
        }
    }
}

.landscape-blocker{
    display: none;
}


@keyframes landscapeSwitcher {
    0% {
        transform-origin: center;
        transform: rotate3d(0, 0, 1, 0deg);
        opacity: 0;
    }

    50% {
        transform-origin: center;
        transform: rotate3d(0, 0, 1, -90deg);
        opacity: 1;
    }
    100% {
        transform-origin: center;
        transform: rotate3d(0, 0, 1, -90deg);
        opacity: 1;
    }
}
